import { SubGstrOneSummaryGridComponent } from './../../../returns/gstr-one/sub-gstr-one-summary-grid/sub-gstr-one-summary-grid.component';
import { DialogTypes } from '../../../shared/Constants';
import { getCurrentDateTimeForFileName } from '../../../shared/helpers/date-time-helper';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { DataColumnList } from '../../../shared/models/get/get-md-global/get-column-list';
import { GbPagination } from '../../../shared/models/global/pagination/gb-pagination';
import { GlobalSrvConstantsService } from '../../../shared/services/global/global-constants/global-srv-constants.service';
import { SubInvItemDialogComponent } from '../../../einvoice/boew-inv/sub-components/sub-inv-item-dialog/sub-inv-item-dialog.component';
import { SubEinvReqDetailsDialogComponent } from '../../../einvoice/boew-inv/sub-components/sub-einv-req-details-dialog/sub-einv-req-details-dialog.component';
import { GlobalApiService } from '../../../shared/services/global/global-api/global-api.service';
import { SingleGstinPeriod } from '../../../shared/models/global/gstin-period';
import { DatePipe } from '@angular/common';
import { SubCnlReqDetailComponent } from '../../../einvoice/boei-einvoices/sub-cnl-req-detail/sub-cnl-req-detail.component';
import { SendEmailComponent } from '../../../shared/send-email/send-email.component';
import { EmailSubject, EmailContent, MasterEmailType, eInvoiceCopyEmailContent } from '../../../shared/models/common/send-email/send-email';
import { getEInvoiceEmailSubject } from '../../../shared/helpers/common-helper';
import { Dialog } from '../../../shared/Constants';
import { BoConfirmationDialogComponent } from '../../bo-confirmation-dialog/bo-confirmation-dialog.component';
import { DataRetreivalService } from '../../services/dataRetreival/data-retreival.service';
import { MasterService } from '../../services/Masters/master.service';
import { SubEwbillItemDialogComponent } from 'src/app/eway/boew-eways/sub-components/sub-ewbill-item-dialog/sub-ewbill-item-dialog.component';
import { EWayDownloadRequest, OutwardData } from '../../models/global/boew-eway/get-boew-eway-search';
import { EwayBillService } from '../../services/ewayBillService/eway-bill.service';
import { AccountSetupService } from '../../services/account-setup/account-setup.service';
import { Gstr2aService } from '../../services/Returns/gstr2-a/gstr2a.service';
import { GtaService } from '../../services/gta/gta.service';
import { TaxNoticeService } from '../../services/taxnotice/tax-notice.service';
import { CashLedgerPdfInputModel } from '../../models/gstRefund/gst-refund';
import { GstRefundService } from '../../services/gstRefund/gst-refund.service';
import { SaveAndPrepareData } from '../../models/post/pst-md-file-returns/post-file-return-data';
import { FileReturnsService } from '../../services/Returns/file-returns/file-returns.service';

@Component({
    selector: 'app-bo-global-table',
    templateUrl: './bo-global-table.component.html',
    styleUrls: ['./bo-global-table.component.css'],
    providers: [DatePipe]
})
export class BoGlobalTableComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @Input() dataList: Subject<any[]>;
    @Input() columnList: Subject<DataColumnList[]>;
    @Input() columnFilterList: Subject<DataColumnList[]>;
    @Input() pageCount: Subject<number>;
    @Input() pageReset: Subject<number>;
    @Input() multiSelection: Subject<boolean>;
    @Input() filterPagination: boolean = false;
    @Input() pageName: string;
    @Output() pageEvents = new EventEmitter<GbPagination>();
    @Output() dataSelected = new EventEmitter<any>();
    @Output() dataSelectedForEditMaster = new EventEmitter<any>();
    @Output() dataSelectedGSTNandPeriod = new EventEmitter<any>();
    @Output() manageOrganization = new EventEmitter<any>();
    @Output() removeOrganization = new EventEmitter<any>();
    @Output() removeStandardFileFromDataUpload = new EventEmitter<any>();
    @Output() terminateLongRunningRequest = new EventEmitter<any>();
    @Output() getID = new EventEmitter<any>();
    @Output() OpenNoOfSplits = new EventEmitter<any>();
    @Output() DataRetrivalStatusChange = new EventEmitter<any>();
    @Output() LedgerStatusChange = new EventEmitter<any>();
    @Output() DataRetrivalRequestPeriodChange = new EventEmitter<any>();
    @Output() TaxNoticeRefresh = new EventEmitter<any>();
    @Input() isGet: string = null;
    columns = new FormControl();
    displayedColumns: DataColumnList[] = [];
    displayedFilterColumns: DataColumnList[] = [];
    additionalColumns: DataColumnList[] = [];
    selection = new SelectionModel<any>(true, []);
    pageEvent: PageEvent;
    pageParameters: GbPagination = {} as GbPagination;
    pageLength: number;
    headerID: number[] = [];
    responseHeaderID: number[];
    displayColumns: string[] = [];
    tableMultiSelect: boolean;
    dataRetreival: boolean = false;
    gstr2a: boolean = false;
    gstinPeriod: SingleGstinPeriod;
    time: string;
    downloadingElement: number;
    gettingReturnStatusElement: number;
    downloadingElementForRETTRACk: number;
    isDownloading = false;
    checkCashLedgerpdfDownloading: number;
    isDisable = false;
    isDisableMain = false;
    public statusList = Status_LIST;
    public requestFrequencyList = RequestPeriod_LIST;
    AnyOnePresent = 0;
    DataRetreivalStatus = "Status";
    RequestPeriodStatus = "Req.Period";
    tableName : string = "";
    @Output() selectGSTR6TableData = new EventEmitter<any>();
    @Output() dataRetrievalSchedulerAction = new EventEmitter<any>();
    @Output() editDataRetrievalScheduler = new EventEmitter<any>();
    dataSource = new MatTableDataSource<any>(null);
    downloadingJsonElement: number;
    filterColumnName:string=null;
    public searchValue: any = {};
    selectedOrganizationDetails: string;
    private setting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }
    applyFilterWithEnter(filterValue: string) {      
        if(this.pageName == "Upload" || this.pageName == "Document"){
            let value = {colname:this.filterColumnName,value:filterValue};
            this.pageParameters.filterValue = value;
            if(this.filterColumnName == "Select Filter"){
                this.pageParameters.filterValue = null;
                this.pageEvents.emit(this.pageParameters);
            }
            else if(this.filterColumnName == null || this.filterColumnName == "" || this.filterColumnName == undefined){
                this.snackBar.open('Please select column to Filter.', 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['alert-snackbar']
                });
            }
            else if((filterValue == "" || filterValue == null || filterValue == undefined) &&
            this.filterColumnName != null && this.filterColumnName != "" && this.filterColumnName != undefined){
                this.pageParameters.filterValue = null;
                this.pageEvents.emit(this.pageParameters);
            }
            else if(filterValue == "" || filterValue == null || filterValue == undefined){
                this.pageParameters.filterValue = null;
                this.pageEvents.emit(this.pageParameters);
            }
            else{
                this.pageEvents.emit(this.pageParameters);
            }
        }
        else{
            this.dataSource.filter = filterValue.trim().toLowerCase();   
        }
        }
        
    applyFilter(filterValue: string) {    
        if(this.pageName == "Upload" || this.pageName == "Document"){
            if(this.filterColumnName != null && this.filterColumnName != "" && this.filterColumnName != undefined){
              return;
            }
            this.dataSource.filter = filterValue.trim().toLowerCase();  
        }
        else{
            this.dataSource.filter = filterValue.trim().toLowerCase();   
        }
    }    
        clearColumn(columnKey: string): void {
            this.searchValue[columnKey] = null;
            delete this.searchValue[columnKey];
            this.applyFilter(columnKey);
          }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
        
    }
    

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private globalApiService: GlobalApiService,
        private datePipe: DatePipe,
        private dataRetreivalService: DataRetreivalService,
        private ewayBillService: EwayBillService,
        private Masterservice: MasterService,
        private gstr2aService: Gstr2aService,
        private accountSetupService: AccountSetupService,
        private gtaService :GtaService,
        private taxNoticeService: TaxNoticeService,
        private fileReturnsService: FileReturnsService,
        private gstrefundservice: GstRefundService) { }

    openDocumentDialog(rowID: string, docDate: string, docNo: string, docType: string, entityGstin: string, supplyType: string, irnEntityGstin: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: rowID,
            documentDate: docDate,
            documentNo: docNo,
            documentType: docType,
            entityGstin: entityGstin,
            supplyType: supplyType,
            irnEntityGstin: irnEntityGstin

        };

        const dialogRef = this.dialog.open(SubInvItemDialogComponent, dialogConfig);
        dialogRef.updateSize('90%', '65%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }
    openViewSummaryPopUp(entityGSTIN: string, reportedPeriod: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            GSTIN: entityGSTIN,
            reportedPeriod: reportedPeriod
        };

        const dialogRef = this.dialog.open(SubGstrOneSummaryGridComponent, dialogConfig);
        dialogRef.updateSize('90%', '65%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openEIRequestDialog(rowID: string, type: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: rowID,
            requestType: type
        };

        const dialogRef = this.dialog.open(SubEinvReqDetailsDialogComponent, dialogConfig);
        dialogRef.updateSize('90%', '65%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }
    openEICnlRequestDialog(rowID: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: rowID
        };

        const dialogRef = this.dialog.open(SubCnlReqDetailComponent, dialogConfig);
        dialogRef.updateSize('90%', '65%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    masterToggle() {
        
        if(this.isGet && (this.isGet == "dataretrievalGET")){
            this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row =>{
            if(row.status === 'Error' || row.status === 'NoData')
            this.selection.select(row);
            else
            this.selection.deselect(row);
            });
        }
        else{
             this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
        }
        
        this.isAllSelected() ?
            this.selectGSTR6TableData.emit(true) :
            this.selectGSTR6TableData.emit(false);
    }
    masterToggleDataRetrival(val) {
        
        if(this.isGet && (this.isGet == "dataretrievalGET")){
            this.isAllSelectedDataRetrival(val) ?
            this.selection.clear() :
            this.dataSource.data.forEach(row =>{
            if(row.status === 'Error' || row.status === 'NoData')
            this.selection.select(row);
            });
        }
        else{
             this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
        }
        
        
    }
    isAllSelectedDataRetrival(checkedorNot) {
        
        if(checkedorNot && !checkedorNot.checked){
            const numSelected = this.selection.selected.length;
            const numRows = this.dataSource.data.length;
            return true;
        }
        else{
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
        }
        
        
    }
    isAllSelectedDataRetrival1(checkedorNot) {
        
        if(checkedorNot && !checkedorNot.checked){
            const numSelected = this.selection.selected.length;
            const numRows = this.dataSource.data.length;
            return true;
        }
        else{
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return true;
        }
        
        
    }

    checkboxLabel(row?: any): string {
        if(row){
          
          if(this.isGet && (this.isGet == "dataretrievalGET")){
            
            if(this.AnyOnePresent !== 0)
            this.isDisableMain = false;

            if(row.status === 'Error' || row.status === 'NoData' || row.status === 'Accepted' || row.status === 'Rejected'
            || row.status === 'No action' || row.status === 'Deferred'){
                this.AnyOnePresent = 1;
                this.isDisable = false;
            }
                
              else
                this.isDisable = true
          }
          
        }
        this.dataSelected.next(this.selection);
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.headerId + 1}`;
    }

    selectRow(row) {
       
    }

    sortData(sort: Sort) {
        
        let tempDataList: any;
        this.dataList.subscribe((data: any) => {
            if (data == null)
                return;
            tempDataList = data.slice();
        });
        if (tempDataList == undefined)
            return;
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = tempDataList;
            return;
        }
        this.dataSource.data = tempDataList.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'documentNo': return this.compare(a.documentNo, b.documentNo, isAsc);
                case 'documentDate': return this.compare(a.documentDate, b.documentDate, isAsc);
                case 'supplyType': return this.compare(a.supplyType, b.supplyType, isAsc);
                case 'entityGstin': return this.compare(a.entityGstin, b.entityGstin, isAsc);
                case 'fromGstin': return this.compare(a.fromGstin, b.fromGstin, isAsc);
                case 'fromPlace': return this.compare(a.fromPlace, b.fromPlace, isAsc);
                case 'fromPincode': return this.compare(a.fromPincode, b.fromPincode, isAsc);
                case 'stateName': return this.compare(a.stateName, b.stateName, isAsc);
                case 'toGstin': return this.compare(a.toGstin, b.toGstin, isAsc);
                case 'toPlace': return this.compare(a.toPlace, b.toPlace, isAsc);
                case 'toPinCode': return this.compare(a.toPinCode, b.toPinCode, isAsc);
                case 'totalDocValue': return this.compare(a.totalDocValue, b.totalDocValue, isAsc);
                case 'g2aDocumentNumber': return this.compare(a.g2aDocumentNumber, b.g2aDocumentNumber, isAsc);
                case 'g2aDocumentDate': return this.compare(a.g2aDocumentDate, b.g2aDocumentDate, isAsc);
                case 'g2aDocumentValue': return this.compare(a.g2aDocumentValue, b.g2aDocumentValue, isAsc);
                case 'g2aTaxablevalue': return this.compare(a.g2aTaxablevalue, b.g2aTaxablevalue, isAsc);
                case 'g2aIgst': return this.compare(a.g2aIgst, b.g2aIgst, isAsc);
                case 'g2aIgst': return this.compare(a.g2aIgst, b.g2aIgst, isAsc);
                case 'g2aCgst': return this.compare(a.g2aCgst, b.g2aCgst, isAsc);
                case 'g2aCgst': return this.compare(a.g2aCgst, b.g2aCgst, isAsc);
                case 'documentType': return this.compare(a.documentType, b.documentType, isAsc);
                case 'vendorGstin': return this.compare(a.vendorGstin, b.vendorGstin, isAsc);
                case 'g2aPlaceOfSupply': return this.compare(a.g2aPlaceOfSupply, b.g2aPlaceOfSupply, isAsc);
                case 'userAction': return this.compare(a.userAction, b.userAction, isAsc);
                case 'subStatus': return this.compare(a.subStatus, b.subStatus, isAsc);
                case 'requestID': return this.compare(a.requestID, b.requestID, isAsc);
                case 'gstin': return this.compare(a.requestID, b.requestID, isAsc);
                case 'reportedPeriod': return this.compare(a.requestID, b.requestID, isAsc);
                case 'transactionType': return this.compare(a.requestID, b.requestID, isAsc);
                case 'requestedOn': return this.compare(a.requestID, b.requestID, isAsc);
                case 'returnType': return this.compare(a.requestID, b.requestID, isAsc);
                case 'requestedBy': return this.compare(a.requestID, b.requestID, isAsc);
                case 'status': return this.compare(a.requestID, b.requestID, isAsc);
                default: return 0;
            }
        });
    }

    openSendEmailDialog(irn: string, toEmailAddresses: string, ccEmailAddresses: string, documentNumber: string, entityLegalName: string, customerLegalName: string, vendorPoreferenceNo: string, documentDate: string, outwardDocumentHeaderId: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            toEmailAddresses: toEmailAddresses,
            ccEmailAddresses: ccEmailAddresses,
            bccEmailAddresses: '',
            subject: getEInvoiceEmailSubject(documentNumber, entityLegalName, customerLegalName, vendorPoreferenceNo),
            content: EmailContent.eInvoiceCopy,
            referenceData: irn + ',' + documentNumber,
            emailType: MasterEmailType.eInvoiceCopy,
            attachmentsPath: '',
            isHtmlContent: true,
            documentNumber: documentNumber,
            hasAttachments: true,
            enableBCCControl: false,
            irn: irn,
            documentDate: this.datePipe.transform(documentDate, 'dd/MM/yyyy HH:mm'),
            entityLegalName: entityLegalName,
            customerLegalName: customerLegalName,
            outwardDocumentHeaderId: outwardDocumentHeaderId
        };

        const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
        dialogRef.updateSize('90%', '70%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ngOnInit() {
        this.time = this.datePipe.transform(new Date());
        this.gstinPeriod = {} as SingleGstinPeriod;
        this.dataSource.data = [] as any;
        this.multiSelection.subscribe((data: any) => {
            if (data == null) {
                return;
            }
            this.tableMultiSelect = data;
        });


        if (this.pageName == "dataretrieval") {
            this.dataRetreival = true;
        }
        else if (this.pageName == "GSTR2A") {
            this.gstr2a = true;
        }
        else {
            this.dataRetreival = false;
            this.gstr2a = false;

        }

        if(this.columnFilterList != undefined){
            this.columnFilterList.subscribe((data: any) =>{
                this.displayedFilterColumns = [];
                this.displayedFilterColumns = data;
                if (this.displayedFilterColumns == null) {
                    return;
                }
            })
        }
        this.columnList.subscribe((data: any) => {
            
            this.displayedColumns = [];
            this.displayColumns = [];
            this.additionalColumns = [];
            this.displayedColumns = data;
            if (this.displayedColumns == null) {
                return;
            }
            if (this.tableMultiSelect) {
                this.displayColumns.push('select');
            }
           
            if (this.pageName == "dataretrieval") {
                
            }

            this.displayedColumns.forEach(x => {
                if (x.isDefault) {
                    this.displayColumns.push(x.columnName);
                } else {
                    this.additionalColumns.push(x);
                }
            });
        });
        this.dataList.subscribe((data: any) => {
            if (data == null) {
                this.dataSource.data = [];
                return;
            }
            this.selection.clear();
            // if(this.pageParameters.filterValue != undefined && this.pageParameters.filterValue != null){
            //     if(this.pageParameters.filterValue.colname != null && this.pageParameters.filterValue.colname != null && this.pageParameters.filterValue.value != "" && this.pageParameters.filterValue.value != null){
            //         this.dataSource.paginator = this.paginator;
            //     }
            // }
            this.dataSource.data = data;
            if (data && this.paginator && data.length <= this.paginator.pageSizeOptions[0] && !this.dataRetreival) {
                this.paginator.pageSize = 5;
            
            }
        });
        this.pageCount.subscribe((data: number) => {
            this.pageLength = data;
        });
        if (this.pageReset) {
            this.pageReset.subscribe((data: number) => {
                if (this.paginator)
                    this.paginator.firstPage();
            });
        }

        this.globalApiService.stopGetReturnStatusLoader.subscribe((data: any) => {
            if (data == true)
                this.gettingReturnStatusElement = undefined;
        })

    }

    showUploadError(uploadErrorMessage: string) {

        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.UploadError;
        dialogTemplate.Message = uploadErrorMessage;

        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });

    }

    ngAfterViewInit() {
        if (this.dataRetreival) {
            this.pageParameters.pageSize = 20;
        }
        else if (this.gstr2a) {
            this.pageParameters.pageSize = 10;
        }
        else {
            this.pageParameters.pageSize = 5;
        }
        this.pageParameters.currentPage = 1;
        this.pageEvents.emit(this.pageParameters);
    }

    goTo(p: number) { this.paginator.pageIndex = p; }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getPageEvent(event?: PageEvent) {
        this.selection.clear();
        this.pageParameters.pageSize = event.pageSize;
        this.pageParameters.currentPage = event.pageIndex + 1;
        this.pageEvents.emit(this.pageParameters);
    }

    additionalColumnSelect(event) {
        const columnAdd: DataColumnList = {} as DataColumnList;
        columnAdd.columnName = event.source.value;
        columnAdd.alias = event.source.viewValue;
        if (this.displayColumns.length < 25 && event.source.selected) {
            this.displayColumns.splice(this.displayColumns.length, 0, event.source.value);
        } else if (!event.source.selected) {
            this.displayColumns = this.displayColumns.filter(x => x !== event.source.value);
        } else {
            this.snackBar.open('No more selections', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
        }
    }

    downloadReport(fileID) {
        this.isDownloading = true;
        this.globalApiService.downloadFile('report', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            let dateTime = new Date();
            anchor.download = 'StdReport' + "_" + this.time
            anchor.href = url;
            anchor.click();
            this.isDownloading = false;
        });
    }

    getCachRefreshStatus(MastertypeName)
    {             
                    switch (MastertypeName)
                    {
                        case "Items":
                            this.tableName = "MasterItem"; break;
                        case "Customer":
                            this.tableName = "MasterCustomer"; break;
                        case "Supplier":
                            this.tableName = "MasterVendor"; break;
                        case "PO":
                            this.tableName = "MasterPO"; break;
                    }
      this.Masterservice.GetCacheRefreshStatus(this.tableName).subscribe(res=>{
        if(res){
         this.Masterservice.MasterDeleteStatus.next(res.data);
        }
       
      },
      error => {
        let errorStatus;
        let errorMessage;
        if (error.error.message !== undefined) {
            errorStatus = error.error.status;
            errorMessage = error.error.message.substring(0, 90);
        } else {
            errorStatus = error.status;
            errorMessage = error.error;
        }
        this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
        });    
    });
    }

    DeleteMasterRecord(Code, i, MasterType) {

        // 
        this.downloadingElement = i;
        this.Masterservice.DeleteMasterRecord(Code, MasterType).subscribe(
            data => {

                console.log("delete:" + data.data);
                if (data.data == "success") {
                    this.snackBar.open('Deleted Successfully', 'OK', {
                        panelClass: ['success-snackbar']
                    });
                    this.getCachRefreshStatus(MasterType);
                }
                this.downloadingElement = undefined;
                this.pageParameters.pageSize = 5;
                this.pageParameters.currentPage = 1;
                this.pageEvents.emit(this.pageParameters);
                //this.pageEvents.emit();
                

            });
    }

    EditMasterRecord(Code, i, MasterType) {
        this.dataSelectedForEditMaster.emit(Code);
    }

    download_Reports_GSTR1Save(element) {

        const saveAndPrepareData: SaveAndPrepareData = {} as SaveAndPrepareData;

        saveAndPrepareData.returnType = "GSTR1SaveExcel";
        saveAndPrepareData.entityGstin = element.gstin;
        saveAndPrepareData.reportedPeriod = element.reportedPeriod;
        saveAndPrepareData.isOverWrite = element.requestID;
        saveAndPrepareData.transactionType = element.transactionType;
        saveAndPrepareData.isPrepareJson = false;
        this.fileReturnsService.doGSTR1SaveHistoryReportRequest(saveAndPrepareData).subscribe((data: any) => {

            if (data) {
                if (data.data == "Success") {
                    this.snackBar.open('Requested Submitted for GSTR1 save report, please check status and download report over GSTR1 save Excel tab', 'OK', {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['success-snackbar']
                    });
                }
            }

        },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            })
    }

    download_Reports_IMSSave(element) {

        const saveAndPrepareData: SaveAndPrepareData = {} as SaveAndPrepareData;

        saveAndPrepareData.returnType = "IMSSaveExcel";
        saveAndPrepareData.entityGstin = element.gstin;
        saveAndPrepareData.reportedPeriod = element.reportedPeriod;
        saveAndPrepareData.isOverWrite = element.requestID;
        saveAndPrepareData.transactionType = element.transactionType;
        saveAndPrepareData.isPrepareJson = false;
        this.fileReturnsService.doIMSSaveHistoryReportRequest(saveAndPrepareData).subscribe(blob => {

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            let dateTime = new Date();
            a.download = 'IMSInputExcel' + "_" + this.time
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

        },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            })
    }

    download_GSTR1SavedExcel_Report(element) {
        this.isDownloading = true;
        this.globalApiService.downloadGSTRFile('report', element.fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            let dateTime = new Date();
            anchor.download = 'GSTR1SavedExcel' + "_" + this.time
            anchor.href = url;
            anchor.click();
            this.isDownloading = false
        });
    }

    download_GSTR_Report(fileID, i, type, element) {        
        this.downloadingElement = i;
        this.globalApiService.downloadGSTRFile('report', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            console.log('i ----'+ i);
            console.log('type ---' + type);
            console.log('element ----' + element.reportName);
            if (type == 'Download GSTR2A')
            anchor.download = this.getDataRetrivalFileNameConversion('GSTR2A Transaction Type',element.gstin,element.reportedPeriod);
                // anchor.download = 'GSTR2AReport' + "_" + getCurrentDateTimeForFileName()
                

            if (type == 'GSTR2A Recon Report')
                anchor.download = 'GSTR2A_Recon_Report' + "_" + getCurrentDateTimeForFileName()

            if (type == 'Download GSTR2B')
                anchor.download = 'GSTR2BReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'CASH')
                anchor.download = 'CashLedger' + "_" + element.gstin + "_" + element.fy;

            if (type == 'BAL')
                anchor.download = 'CashItcBalanceLedgerReport' + "_"  + element.period+ "_" + getCurrentDateTimeForFileName();


            if (type == 'LIABILITY')
                anchor.download = 'LiabilityLedger' + "_" + element.gstin + "_" + element.fy;

            if (type == 'REVRCLM')
                anchor.download = 'ReversalClaimedLedger_' + getCurrentDateTimeForFileName();


            if (type == 'ITC')
                anchor.download = 'CreditLedger' + "_" + element.gstin + "_" + element.fy;

            if (type == 'GSTR1 in excel')
                if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                    anchor.download = 'GSTR1_' + getCurrentDateTimeForFileName()
                else
                    anchor.download = 'GSTR1_' + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            
            if (type == 'GSTR1 in excel (Cons.)')
                anchor.download = 'GSTR1_Consolidated_' + getCurrentDateTimeForFileName()
                
            if (type == 'GSTR1 in excel (Regwise)')
                anchor.download = 'GSTR1_Regwise_' + getCurrentDateTimeForFileName()

            if (type == 'GSTR1and3BHealthReport') {
                if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                    anchor.download = 'GSTR1HealthReport' + "_" + getCurrentDateTimeForFileName()
                else
                    anchor.download = 'GSTR1HealthReport' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR1-Turnover Report' )
                anchor.download = 'GSTR1TurnoverReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'Four Way Reconciliation Report')
                anchor.download = 'FourWayReconciliationReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'Turnover and Output Tax comparison Report')
                anchor.download = 'TurnoverAndOutputTaxComparisionReport' + "_" + getCurrentDateTimeForFileName()
            
            if (type == 'GSTR1 vs GSTR3B comparison report')
                anchor.download = 'GSTR1vsGSTR3Bcomparisonreport' + "_" + getCurrentDateTimeForFileName()
                if (type == 'TCS Comparision Report with GSTR3B')
                anchor.download = 'TCSComparisionReportwithGSTR3B' + "_" + getCurrentDateTimeForFileName()
            
            if (type == 'GSTR1 and Sales Register Recon Report')
                anchor.download = 'GSTR1andSalesRegisterReconReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'ITC Comparision Report')
                anchor.download = 'ITC_ComparisionReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'RCM Liability Comparison Report')
                anchor.download = 'RCMLiability_ComparisionReport' + "_" + getCurrentDateTimeForFileName()

            if (type == 'Vendor ITC Summary Report') {
                if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                    anchor.download = 'DataRetreivalReport' + "_" + getCurrentDateTimeForFileName()
                else
                    anchor.download = 'VendorITCSummaryReport' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR9 - Get Details') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                // anchor.download = 'GSTR9GetDetailsReport' + "_" + getCurrentDateTimeForFileName()
                
            }
            if (type == 'GSTR8 - Get Summary') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                //anchor.download = 'GSTR8GetsummaryDetailsReport' + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR8 - Get TCS') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                //anchor.download = 'GSTR8GettcsDetailsReport' + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'EINV') {
                
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                // if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                //     anchor.download = 'DataRetreivalReport' + "_" + getCurrentDateTimeForFileName()
                // else
                //     anchor.download = 'DataRetreivalReport' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'SalesRegistervsIRPAutopopulatedData')
                anchor.download = 'SalesRegistervsIRPAutopopulatedData' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            anchor.href = url;

            if (type == 'ISDReport')
                anchor.download = 'Reports_GenerateGSTR6' + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR2B') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                // if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                //     anchor.download = 'GSTR2BReport' + "_" + getCurrentDateTimeForFileName()
                // else
                //     anchor.download = 'GSTR2BReport' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR6A') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                // if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                //     anchor.download = 'GSTR6A' + "_" + getCurrentDateTimeForFileName()
                // else
                //     anchor.download = 'GSTR6A' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR6') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
            }
            if (type == 'GSTR1') {
                if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                    anchor.download = 'GSTR1' + "_" + getCurrentDateTimeForFileName()
                else
                    anchor.download = 'GSTR1' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            }
            if (type == 'GSTR3B-Summary') {
                anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                // if (element.gstin.length > 15 || element.reportedPeriod.length > 7)
                //     anchor.download = 'GSTR3B_Multi_GSTIN_Period' + "_" + getCurrentDateTimeForFileName()
                // else
                //     anchor.download = 'GSTR3B' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()

            }
            if (type == 'GSTR-3B-Summary-Consolidated') {
               
                anchor.download = 'GSTR3B_Consolidated_Report' + "_" + getCurrentDateTimeForFileName()
            }

            if (type == 'RETTRACK')
                anchor.download = 'ViewTrack' + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR6A Recon Report')
                anchor.download = 'GSTR6A_Recon_Report' + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR1OutwardsStandardReport')
                anchor.download = 'GSTR1StandardReport' + "_" + getCurrentDateTimeForFileName()
            if (type == 'CustomerWiseGSTR1Report')
                anchor.download = 'CustomerWiseGSTR1Report' + "_" + getCurrentDateTimeForFileName()
            if (type == 'GSTR1 Transaction Type')
            anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);
                //anchor.download = 'GSTR1TransactionType' + "_" + getCurrentDateTimeForFileName()
                

            if (type == 'GSTR1SaveHistory')
                anchor.download = 'GSTR1SaveHistory' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR2B Recon Report')
                anchor.download = 'GSTR2B_Recon_Report' + "_" + getCurrentDateTimeForFileName()
            
            if (type == 'GSTR2A Auto ITC Reco Accepted Report')
                anchor.download = 'GSTR2A_AutoITC_Accepted_Report'+ "_" + element.gstin + "_"  + getCurrentDateTimeForFileName()

            if (type == 'GSTR2B Auto ITC Reco Accepted Report')
                anchor.download = 'GSTR2B_AutoITC_Accepted_Report'+ "_" + element.gstin + "_"  + getCurrentDateTimeForFileName()
            
            if (type == 'ITC04')
                anchor.download = 'ITC04_Report' + "_" + getCurrentDateTimeForFileName()

            if (type == 'Output Tax Comparison')
                anchor.download = 'OutputTaxComparison' + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR8')
                anchor.download = 'GSTR8_Report' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR7')
            anchor.download = this.getDataRetrivalFileNameConversion(type,element.gstin,element.reportedPeriod);

            if (type == 'Auto Recon Report')
                anchor.download = 'Auto_Recon_Report' + "_" + getCurrentDateTimeForFileName()  
            if (type == 'CombinedAutoRecon' && element.reportName == 'Combined 2A & 2B Recon Report')
                anchor.download = 'AutoRecon_CombinedReport_' + "_" + getCurrentDateTimeForFileName() 
            if (type == 'CombinedAutoRecon' && element.reportName == 'Combined 2A & 2B Non-Recon Report')
                anchor.download = 'AutoNonRecon_CombinedReport_' + "_" + getCurrentDateTimeForFileName()            
            
            if(type && type.includes('_Export'))     
                anchor.download = type + "_" + getCurrentDateTimeForFileName()   
            
            if (type == 'JsonToExcelDownload')
                anchor.download = 'JsonToExcelDownload' + "_" + getCurrentDateTimeForFileName()

            if (type == 'AutoRecon_GSTR2A_Cancelled' || type == 'AutoRecon_GSTR2B_Cancelled')
                anchor.download = type + "_" + getCurrentDateTimeForFileName()

            if (type == 'FILEDETL8A')
            anchor.download = this.getDataRetrivalFileNameConversion('GSTR9 - Get 8A',element.gstin,element.reportedPeriod);
            // anchor.download = 'FILEDETL8A' + "_" + getCurrentDateTimeForFileName()
            

            if (type == 'GSTR9-8A Recon Report')
            anchor.download = type + "_" + getCurrentDateTimeForFileName()

            if(type == "GSTR1 in PDF")
            anchor.download = 'GSTR1' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
           
            if (type == 'GSTR7 - Get Summary' || type == 'RETSUM7')
            anchor.download = 'GSTR7Summary' + "_" + getCurrentDateTimeForFileName()

            if (type == 'GSTR3B - Auto-Liability')
            anchor.download = 'GSTR3BAutoLiability' + "_" + getCurrentDateTimeForFileName()
            //Dataretrieval-PDF download
            if(type == "GSTR1PDF")
            anchor.download = 'GSTR1' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR6PDF")
            anchor.download = 'GSTR6' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR7PDF")
            anchor.download = 'GSTR7' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR8PDF")
            anchor.download = 'GSTR8' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR9PDF")
            anchor.download = 'GSTR9' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR3BPDF")
            anchor.download = 'GSTR3B' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
            if(type == "GSTR3BAutoLiabPDF")
            anchor.download = 'GSTR3BAutoLiabPDF' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()

            if (type == 'TimelyIRNStdReport')
            anchor.download = 'TimelyIRNStdReport' + "_" + getCurrentDateTimeForFileName()        
            if (type == 'TCS Tracker') {
                if (element.gstin == 'NA') {
                    anchor.download = 'TCSTracker' + "_" + getCurrentDateTimeForFileName()
                }
                else {
                    anchor.download = 'TCSTracker' + "_" + element.gstin + "_" + element.reportedPeriod + "_" + getCurrentDateTimeForFileName()
                }
            }
            if (type == 'GSTR6 - Get Summary' || type == 'RETSUM6')
            anchor.download = 'GSTR6Summary' + "_" + getCurrentDateTimeForFileName()

            if (type == "GSTR2X - Get TDS-TCS" || type == 'TDSTCS')
            anchor.download = 'GSTR2X_Get_TDS_TCS' + "_" + getCurrentDateTimeForFileName()
            if (type == "preferenceDownload")
                anchor.download = 'GetPreference' + "_" + getCurrentDateTimeForFileName()

            if (type == 'IMS-GETINV')
                anchor.download = 'GETINV_Report_' + getCurrentDateTimeForFileName()
            anchor.click();
            this.downloadingElement = undefined;
        },
            error => {
                this.downloadingElement = undefined;
                this.snackBar.open(
                    'Error Downloading  | Status: ' + error.status, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }
    getDataRetrivalFileNameConversion(type:string,gstin:string,period:string):string{
        
        
        let filename = type+'_';
        if(gstin.includes(',')){
        //Multi GSTIN Present
        filename = filename+'Multi-GSTIN_';
        }
        else{
            filename = filename+gstin+'_';
        }
        if(period.includes(',')){
         //Multi Period Present
         filename = filename+'Multi-Period_';
        }
        else{
            filename = filename+period+'_';
        }
        filename  = filename+getCurrentDateTimeForFileName();
        
        return filename;
        

    }
    download_moowr_Reports(fileID, i, type, element) {
        this.globalApiService.downloadFile('report', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = type + "_" + getCurrentDateTimeForFileName();
            anchor.href = url;
            anchor.click();
            this.downloadingElement = undefined;
        });
    }

    download_Reports(fileID, i, type, element) {
       
        if (type == 'StandardReport' || type == 'StandardHeaderReport' || type == 'StandardInwardReport' || type == 'eInvoice-EWB BoltOn Comparison Report'
            || type == 'API Usage Summary Report' || type == 'GstnVal' || type == 'DocumentSearch' ||
            type == 'Eway Bill Report From NIC' || type == 'Eway Bill Details Report From NIC' || type == 'GSTR1-EWB Recon Report' || type =='Sales Register-EWB Recon Report' 
            || type == 'Output Tax Comparison' || type == 'Error Report' || type == 'Other Party Eway Bill Report From NIC'
             || type == 'Other Party Eway Bill Details Report From NIC' || type == 'EWB Details from EWB portal for Transportation'||
              type == "EInvoice-EWB Recon Report" || type == 'IRN comparison between Books and Bolton'  
              || type == 'File Upload Usage Summary Report') {

                this.downloadReport_stdReport(fileID,i,type,element);
        }
        else {
            this.download_GSTR_Report(fileID,i,type,element);
        }
    }

    downloadReport_stdReport(fileID, i, type, element) {
        this.downloadingElement = i;
        this.globalApiService.downloadFile('report', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            //let dateTime = new Date();
            if (type == 'IRN comparison between Books and Bolton')
                anchor.download = "IRNComparisonBetweenBooksAndBolton" + "_" + getCurrentDateTimeForFileName()
            if (type == 'StandardReport')
                anchor.download = element.reportName + "_" + getCurrentDateTimeForFileName()
            if (type == 'StandardHeaderReport')
                anchor.download = element.reportName + "_" + getCurrentDateTimeForFileName();
            //anchor.download = 'StandardReport' + "_" + getCurrentDateTimeForFileName()
            if (type == 'StandardInwardReport')
                anchor.download = element.reportName + "_" + getCurrentDateTimeForFileName()

            if (type == 'eInvoice-EWB BoltOn Comparison Report')
                anchor.download = 'eInvoice-EWB BoltOn Comparison Report' + "_" + getCurrentDateTimeForFileName()
                if (type == 'API Usage Summary Report'){
                    if(element.parameter != null && element.parameter != undefined){
                        var stringData = element.parameter;
                        if(element.parameter.includes("GenerateByUploadedON:true"))
                        anchor.download = 'API Usage Summary Report' + "_UploadedOn_" + getCurrentDateTimeForFileName()
                        else
                        anchor.download = 'API Usage Summary Report' + "_DocumentDate_" + getCurrentDateTimeForFileName()
                    }
                    else
                    anchor.download = 'API Usage Summary Report' + "_" + getCurrentDateTimeForFileName()
                }
                if (type == 'File Upload Usage Summary Report'){
                    if(element.parameter != null && element.parameter != undefined){
                        var stringData = element.parameter;
                        if(element.parameter.includes("GenerateByUploadedON:true"))
                        anchor.download = 'File Upload Usage Summary Report' + "_UploadedOn_" + getCurrentDateTimeForFileName()
                        else
                        anchor.download = 'File Upload Usage Summary Report' + "_DocumentDate_" + getCurrentDateTimeForFileName()
                    }
                    else
                    anchor.download = 'File Upload Usage Summary Report' + "_" + getCurrentDateTimeForFileName()
                }

            if (type == 'GstnVal')
                anchor.download = 'GstnValidationReport' + "_" + getCurrentDateTimeForFileName()
            if (type == 'DocumentSearch')
                anchor.download = 'DocumentSearchReport' + "_" + getCurrentDateTimeForFileName()

            //anchor.download = 'GSTR1' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Eway Bill Report From NIC')
                anchor.download = 'Eway_Bill_Report_From_NIC' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Eway Bill Details Report From NIC')
                anchor.download = 'Eway_Bill_Details_Report_From_NIC' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Other Party Eway Bill Report From NIC')
                anchor.download = 'Other_Party_Eway_Bill_Report_From_NIC' + "_" + getCurrentDateTimeForFileName()
            if (type == 'EWB Details from EWB portal for Transportation')
                anchor.download = 'EWB_Details_from_EWB_portal_for_Transportation' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Other Party Eway Bill Details Report From NIC')
                anchor.download = 'Other_Party_Eway_Bill_Details_Report_From_NIC' + "_" + getCurrentDateTimeForFileName()
            if (type == 'GSTR1-EWB Recon Report')
                anchor.download = 'GSTR1_EWB_Recon_Report' + "_" + getCurrentDateTimeForFileName()
            if(type == 'Sales Register-EWB Recon Report')
               anchor.download = 'Sales_Register_EWB_Recon_Report' + "_" + getCurrentDateTimeForFileName()
            if (type == 'EInvoice-EWB Recon Report')
                anchor.download = 'EInvoice_EWB_Recon_Report' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Output Tax Comparison')
                anchor.download = 'OutputTaxComparison' + "_" + getCurrentDateTimeForFileName()
            if (type == 'Error Report')
                anchor.download = 'Error_Report' + "_" + getCurrentDateTimeForFileName()
            anchor.href = url;
            anchor.click();
            this.downloadingElement = undefined;
        },
            error => {
                this.downloadingElement = undefined;
                this.snackBar.open(
                    'Error Downloading  | Status: ' + error.status, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }




    downloadFileUpload(fileID, fileName) {
        
        this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = true;
        this.globalApiService.downloadFile('inv', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
        },
            error => {
                this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
                const filename = this.dataSource.data.find(item => item.fileId === fileID).fileName;
                this.snackBar.open(
                    'Error Downloading ' + filename + '. | Status: ' + error.status + '. | Error Message: ' + error.name, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }
    downloadErrorFileForRETTRACK(fileID, i, fileName) {

        //this.isDownloading = true;
        this.downloadingElementForRETTRACk = i;
        this.globalApiService.downloadFile('inv', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = 'Error_' + fileName;
            anchor.href = url;
            anchor.click();
            //this.isDownloading = false;
            this.downloadingElementForRETTRACk = undefined;
        });
    }
    downloadErrorFile(fileID, fileName) {
        this.isDownloading = true;
        this.globalApiService.downloadFile('inv', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = 'Error_' + fileName;
            anchor.href = url;
            anchor.click();
            this.isDownloading = false;
        });
    }
    downloadSuccessFile(element) {
        var fileID = element.responseFileID;
        var fileName = element.fileName;
        if(fileID>0){
            this.isDownloading = true;
            this.globalApiService.getSuccessFile('inv', fileID).subscribe((data: any) => {
                var split = fileName.split(fileName.substring(fileName.lastIndexOf('.')));
                const blob = new Blob([this.convertbase64toArrayBuffer(data.stream)], { type: data.contentType });
                var a = document.createElement("a");
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.download = 'Success_' + split[0] + data.fileName;
                a.href = url;
                a.click();
                this.isDownloading = false;
            });
        }
        else{
            this.isDownloading = true;
             this.globalApiService.generateSuccessFile(element.uploadId).subscribe((data:any)=>{
                 if(data>0){
                    this.globalApiService.getSuccessFile('inv', data).subscribe((data: any) => {
                        var split = fileName.split(fileName.substring(fileName.lastIndexOf('.')));
                        const blob = new Blob([this.convertbase64toArrayBuffer(data.stream)], { type: data.contentType });
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        const url = window.URL.createObjectURL(blob);
                        a.download = 'Success_' + split[0] + data.fileName;
                        a.href = url;
                        a.click();
                        this.isDownloading = false;
                    });
                 }
             });
        }
    }

    downloadTaxNotice(fileID,gstin,noticeDemandOrderId) {
        this.globalApiService.downloadTaxNotice(fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = gstin+'_'+noticeDemandOrderId;
            anchor.href = url;
            anchor.click();
            //this.isDownloading = false;
            this.downloadingElementForRETTRACk = undefined;
        });
    }

    convertbase64toArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len)
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    checkIsDownloading(i) {
        return this.downloadingElement == i;
    }
    checkIsDownloading_RefundCashLedgerPDF(i) {
        return this.checkCashLedgerpdfDownloading == i;
    }
    checkIsDownloadingForRETTRACK(i) {
        return this.downloadingElementForRETTRACk == i;
    }
    checkIsGetReturnStatusRunning(i) {

        return this.gettingReturnStatusElement == i;

    }
    checkIsdownloadGSTR1SaveRunning(i) {

        return this.downloadingElement == i;

    }
    GetReturnStatusButtonClick(row, i) {
        this.gettingReturnStatusElement = i;
        // let requestid = row.requestID;
        // let GSTIN = row.gstin;
        // let Period = row.reportedPeriod
        let selectedValue = row;
        if (selectedValue) {
            this.dataSelectedGSTNandPeriod.emit(selectedValue);
        }

    }
    download6aReport(fileID, i, type, element) {

        this.downloadingElement = i;
        var data = { entityGSTIN: element.gstin, period: element.reportedPeriod, fileId: fileID, actionType: element.transactionType };
        this.dataRetreivalService.DownloadGSTR6AReport(data).subscribe((data: any) => {

            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            let currentdate = new Date();
            anchor.download = 'GSTR6A_' + element.transactionType + '_' + element.gstin + '_' + element.reportedPeriod + '.xlsx';
            anchor.href = url;
            anchor.click();
            this.downloadingElement = undefined;
        },
            error => {
                // this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
                // const filename = this.dataSource.data.find(item => item.fileId === fileID).fileName;
                this.snackBar.open(
                    'Error Downloading ' + 'GSTR6A_' + element.transactionType + '_' + element.gstin + '_' + element.reportedPeriod + '.xlsx' + '. | Status: ' + error.status + '. | Error Message: ' + error.name, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
                this.downloadingElement = undefined;
            });
    }
    downloadDataRetrievalGSTR1Report(fileID, i, type, element) {

        this.downloadingElement = i;
        var data = { gstin: element.gstin, period: element.reportedPeriod, returnType: element.transactionType, requestId: fileID };
        this.dataRetreivalService.DownloadDataRetrievalGSTR1Report(data).subscribe((data: any) => {

            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            let currentdate = new Date();
            anchor.download = 'GSTR1_' + element.transactionType + '_' + element.gstin + '_' + element.reportedPeriod + '.xlsx';
            anchor.href = url;
            anchor.click();
            this.downloadingElement = undefined;
        },
            error => {
                // this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
                // const filename = this.dataSource.data.find(item => item.fileId === fileID).fileName;
                this.snackBar.open(
                    'Error Downloading ' + 'GSTR6A_' + element.transactionType + '_' + element.gstin + '_' + element.reportedPeriod + '.xlsx' + '. | Status: ' + error.status + '. | Error Message: ' + error.name, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
                this.downloadingElement = undefined;
            });
    }


    // Eway Bill Details Dialog
    openEwayBillDetailsDialog(invoiceNumer: string, documentNo: string, documentDate: string, entityGstin: string, documentType: string, supplyType: string, actionCode: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: invoiceNumer,
            documentNo: documentNo,
            documentDate: documentDate,
            entityGstin: entityGstin,
            documentType: documentType,
            supplytype: supplyType,
            actionCode: actionCode
        };
        const dialogRef = this.dialog.open(SubEwbillItemDialogComponent, dialogConfig);
        dialogRef.updateSize('90%', '65%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openEwayBillSendEmailDialog(ewayBillNumber: string, toEmailAddresses: string, ccEmailAddresses: string, documentNumber: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            toEmailAddresses: toEmailAddresses,
            ccEmailAddresses: ccEmailAddresses,
            bccEmailAddresses: '',
            subject: EmailSubject.eWayBillCopy,
            content: EmailContent.eWayBillCopy,
            referenceData: ewayBillNumber,
            emailType: MasterEmailType.EwayBillCopy,
            attachmentsPath: '',
            isHtmlContent: true,
            documentNumber: documentNumber,
            hasAttachments: true,
            enableBCCControl: false,
        };

        const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
        dialogRef.updateSize('90%', '70%');

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    generateCashLedgerPdf(i, element) {
        this.checkCashLedgerpdfDownloading = i;
        // this.checkCashLedgerpdfDownloading = true;

        let requestModel = new CashLedgerPdfInputModel();
        requestModel.EntityGstin = element.gstin;
        requestModel.RequestID = element.requestID;
        requestModel.FromPeriod = element.fromDate;
        requestModel.ToPeriod = element.toDate;

        //Calling service :
        this.gstrefundservice.generateCashLedgerPDF(requestModel).subscribe((res: any) => {
            //  this.checkCashLedgerpdfDownloading = false;
            this.checkCashLedgerpdfDownloading = undefined;
            var fileName = "CashLedger_" + "_" + getCurrentDateTimeForFileName();
            var _type = 'application/pdf';

            const blob = new Blob([this.convertbase64toArrayBuffer(res.stream)], { type: _type });
            var a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
        }, error => {
            //  this.checkCashLedgerpdfDownloading = false;
            this.checkCashLedgerpdfDownloading = undefined;
            let errorStatus;
            let errorMessage;
            if (error.error.message !== undefined) {
                errorStatus = error.error.status;
                errorMessage = error.error.message.substring(0, 90);
            } else {
                errorStatus = error.status;
                errorMessage = error.error;
            }
            this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['danger-snackbar']
            });
        })
    }

    generateEwayBillSummarypdf(ewayBillnumber: string, fileID: number, headerID: number, i) {
        this.downloadingElement = i;
        let ewayReq = new EWayDownloadRequest();
        ewayReq.organizationId = Number(GlobalApiService.getCurrentOrganizationID());
        ewayReq.organizationName = GlobalApiService.getCurrentOrganizationName();
        ewayReq.isPdfDownload = true;
        // this.isEwbSummaryDownloading = true;

        let outwardData = new OutwardData();
        outwardData.outwardHeaderID = headerID;
        outwardData.fileID = fileID;
        outwardData.ewayBillNumber = ewayBillnumber;

        ewayReq.outwardData.push(outwardData);
        ewayReq.typeOfEwayBill = "S";
        //Calling service :
        this.ewayBillService.getEwayInvoices(ewayReq).subscribe((res: any) => {
            this.downloadingElement = undefined;
            var fileName = "EwayBill_" + ewayBillnumber + "_" + getCurrentDateTimeForFileName();
            var _type = 'application/pdf';

            const blob = new Blob([this.convertbase64toArrayBuffer(res.stream)], { type: _type });
            var a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
        }, error => {
            this.downloadingElement = undefined;
            let errorStatus;
            let errorMessage;
            if (error.error.message !== undefined) {
                errorStatus = error.error.status;
                errorMessage = error.error.message.substring(0, 90);
            } else {
                errorStatus = error.status;
                errorMessage = error.error;
            }
            this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['danger-snackbar']
            });
        })
    }

    loadForceMatchPopUp(fileID, i, element) {
        let reportName = element.reportName.split(" ")[1];
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.ForceMatch;
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.Message = "Perform ForceMatch on PR & " + reportName + "  documents.";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }

    loadAutoForceMatchPopUp(fileID, i, element) 
     {
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.AutoReconForceUpload;
        dialogTemplate.ReportType = "Auto GSTR" + element.reportName.substr(14,2) + " Recon";
        let value = {
            requestID: element.requestID,
            gstin:"",
            reportName:"AutoRecon",
            type: element.reportName.substr(10,6)
        }
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.Message = "Perform ForceMatch on PR & " + element.reportName.substr(14,2) + " documents.";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
      }

    loadAutoDeLinkPopUp(fileID, i, element) {
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.AutoReconDeLinkUpload;
        dialogTemplate.ReportType = "Auto GSTR" + element.reportName.substr(14,2) + " Recon";
        let value = {
          requestID:0,
          gstin:"",
          reportName:"AutoRecon",
          type: element.reportName.substr(10,6)
        }
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.Message = "Perform De-Link on PR & " + element.reportName.substr(14,2) + " documents.";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
      }

    
    loadDelinkPopUp(fileID, i, element) {
        let reportName = element.reportName.split(" ")[1];
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.DeLinkMatch;
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.Message = "Perform De-link on PR & " + reportName + " documents.";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }

    manageAccountSetupOrganization(element) {
        this.manageOrganization.emit(element);
    }
    removeAccountSetupOrganization(element) {
        this.removeOrganization.emit(element);
    }
    removeFileFromDataUpload(element) {
        this.removeStandardFileFromDataUpload.emit(element);
    }
    onTerminateLongRunningRequest(element) {
        this.terminateLongRunningRequest.emit(element);
    }
    emailPopUp(fileID, i, element, reportType) {
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.ReconEmail;
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.ReportType = reportType;
        dialogTemplate.Message = "Do you want to send ITC mismatch notification to vendors who have been enabled to receive it?";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }

    emailAutoPopUp(fileID, i, element, reportType,requestId) {
        let GSTIN=[];
        if(element.gstin == 'NA'){
            GSTIN = element.parameter.split(',');
        }
        else{
            GSTIN.push(element.gstin);
        }
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.AutoReconRequestEmail;
        dialogTemplate.ReportType = "AutoReconEmail";
        let value = {
          Gstins: GSTIN,
          FyPeriods: element.reportedPeriod.split(','),
          Action: element.reportName.substr(10,6),
          requestId : requestId
        }
        dialogTemplate.AdditionalInfo = value;
        dialogTemplate.Message = "Do you want to send ITC mismatch notification to vendors who have been enabled to receive it?";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }

    resendEmail(element) {
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.ReconResendEmail;
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.Message = "Please address the below error(s) before resending mail.";
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }
    onDataRetrievalSchedulerAction(element) {
        this.dataRetrievalSchedulerAction.emit(element);
    }

    onEditDataRetrievalScheduler(element) {
        this.editDataRetrievalScheduler.emit(element);
    }

    downloadLogsForScheduler(element) {

        this.globalApiService.ManageOrganizationNameAndID.subscribe(data => {
            if (data) {
              this.selectedOrganizationDetails = data;
            }
          });

        this.accountSetupService.downloadLogsForScheduler(element.requestID,element.returnType,this.selectedOrganizationDetails).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = element.returnType + "_Scheduler" + "_" + getCurrentDateTimeForFileName() + ".xlsx";
            anchor.href = url;
            anchor.click();
            this.isDownloading = false;
        });
     }

     download_GSTR_SaveJson_Report(fileID,i, type, element1)
    {
        this.downloadingJsonElement = i;
        this.globalApiService.downloadGSTRJsonFile(fileID).subscribe((data: any) => {

            if(data.invoiceCopiesCombined)
            {
                const blob = new Blob([this.convertbase64toArrayBuffer(data.stream)], { type: "application/zip" });
                var a = document.createElement("a");
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                if(element1.returnType == 'GSTR1Json' || element1.returnType == 'GSTR1' )
                var finalZipFileName= 'GSTR1SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod +  "_" + getCurrentDateTimeForFileName()
                if(element1.returnType == 'GSTR6Json' || element1.returnType == 'GSTR6')
                var finalZipFileName= 'GSTR6SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod +  "_" + getCurrentDateTimeForFileName()
                if(element1.returnType == 'GSTR8Json' || element1.returnType == 'GSTR8')
                var finalZipFileName= 'GSTR8SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod +  "_" + getCurrentDateTimeForFileName()

                a.download = finalZipFileName;
                a.click();
                this.downloadingJsonElement = undefined;
            }
            else
            {
                if (!this.setting.element.dynamicDownload) {
                    this.setting.element.dynamicDownload = document.createElement('a');
                }
                const element = this.setting.element.dynamicDownload;
                const fileType = data.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
                element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(data.stream)}`);
                
                if(element1.returnType == 'GSTR1Json' || element1.returnType == 'GSTR1')
                var finalFileName= 'GSTR1SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod + "_" + element1.transactionType + "_" + getCurrentDateTimeForFileName() + ".json"
                if(element1.returnType == 'GSTR6Json' || element1.returnType == 'GSTR6')
                var finalFileName= 'GSTR6SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod + "_" + element1.transactionType + "_" + getCurrentDateTimeForFileName() + ".json"
                if(element1.returnType == 'GSTR8Json' || element1.returnType == 'GSTR8')
                var finalFileName= 'GSTR8SaveJson' + "_" + element1.gstin + "_" + element1.reportedPeriod +  "_" + getCurrentDateTimeForFileName() + ".json"


                element.setAttribute('download', finalFileName);

                var event = new MouseEvent("click");
                element.dispatchEvent(event);
                this.downloadingJsonElement = undefined;
            }


        },
            error => {
                this.downloadingJsonElement = undefined;
                this.snackBar.open(
                    'Error Downloading  | Status: ' + error.status, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }

    download_IMS_SaveJson_Report(fileID, i, type, element1) {
        this.downloadingJsonElement = i;
        this.globalApiService.downloadIMSJsonFile(fileID).subscribe((data: any) => {

            if (data.invoiceCopiesCombined) {
                const blob = new Blob([this.convertbase64toArrayBuffer(data.stream)], { type: "application/zip" });
                var a = document.createElement("a");
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                var finalZipFileName = 'IMSJson' + "_" + element1.gstin + "_" + element1.transactionType + "_" + getCurrentDateTimeForFileName()

                a.download = finalZipFileName;
                a.click();
                this.downloadingJsonElement = undefined;
            }
            else {
                if (!this.setting.element.dynamicDownload) {
                    this.setting.element.dynamicDownload = document.createElement('a');
                }
                const element = this.setting.element.dynamicDownload;
                const fileType = data.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
                element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(data.stream)}`);

                var finalFileName = 'IMSJson' + "_" + element1.gstin + "_" + element1.transactionType + "_" + getCurrentDateTimeForFileName() + ".json"



                element.setAttribute('download', finalFileName);
        
                var event = new MouseEvent("click");
                element.dispatchEvent(event);  
                this.downloadingJsonElement = undefined;
            }
          

        },
            error => {
                this.downloadingJsonElement = undefined;
                this.snackBar.open(
                    'Error Downloading  | Status: ' + error.status, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }

    checkIsdownloadGSTR1SaveJsonFileRunning(i) {
        return this.downloadingJsonElement == i;
    }
    OpenIsseSplits(id,totalsplits,wholeElement){
        this.OpenNoOfSplits.emit(wholeElement);
    }
    StatusClick(statusName,tableType){
        if(tableType=="BalanceLedger")
        {
            if(statusName == "Select"){
                this.DataRetreivalStatus = 'Status';
                let obj = {'statusName':this.DataRetreivalStatus , value : 0}
                this.LedgerStatusChange.emit(obj);
            }else if(statusName == "Error"){
                this.DataRetreivalStatus = statusName;
                let obj = {'statusName':this.DataRetreivalStatus , value : 4}
                this.LedgerStatusChange.emit(obj);
            }else if(statusName == "In Progress"){
                this.DataRetreivalStatus = statusName;
                let obj = {'statusName':this.DataRetreivalStatus , value : 2}
                this.LedgerStatusChange.emit(obj);
            }else if(statusName == "In Queue"){
                this.DataRetreivalStatus = statusName;
                let obj = {'statusName':this.DataRetreivalStatus , value : 1}
                this.LedgerStatusChange.emit(obj);
            }else if(statusName == "No Data"){
                this.DataRetreivalStatus = statusName;
                let obj = {'statusName':this.DataRetreivalStatus , value : 34}
                this.LedgerStatusChange.emit(obj);
            }else if(statusName == "Success"){
                this.DataRetreivalStatus = statusName;
                let obj = {'statusName':this.DataRetreivalStatus , value : 3}
                this.LedgerStatusChange.emit(obj);
            }
            else{
                this.DataRetreivalStatus = 'Status';
            }        
        }
        else
        {
        if(statusName == "Select"){
            this.DataRetreivalStatus = 'Status';
            let obj = {'statusName':this.DataRetreivalStatus , value : 0}
            this.DataRetrivalStatusChange.emit(obj);
        }else if(statusName == "Error"){
            this.DataRetreivalStatus = statusName;
            let obj = {'statusName':this.DataRetreivalStatus , value : 4}
            this.DataRetrivalStatusChange.emit(obj);
        }else if(statusName == "In Progress"){
            this.DataRetreivalStatus = statusName;
            let obj = {'statusName':this.DataRetreivalStatus , value : 2}
            this.DataRetrivalStatusChange.emit(obj);
        }else if(statusName == "In Queue"){
            this.DataRetreivalStatus = statusName;
            let obj = {'statusName':this.DataRetreivalStatus , value : 1}
            this.DataRetrivalStatusChange.emit(obj);
        }else if(statusName == "No Data"){
            this.DataRetreivalStatus = statusName;
            let obj = {'statusName':this.DataRetreivalStatus , value : 34}
            this.DataRetrivalStatusChange.emit(obj);
        }else if(statusName == "Success"){
            this.DataRetreivalStatus = statusName;
            let obj = {'statusName':this.DataRetreivalStatus , value : 3}
            this.DataRetrivalStatusChange.emit(obj);
        }else{
            this.DataRetreivalStatus = 'Status';
        }
    }
        
    }
    requestFrequency(requestFrequencyName){
        
        if(requestFrequencyName == "Select"){
            this.RequestPeriodStatus = 'Req.Period';
            let obj = {'statusName':this.RequestPeriodStatus , value : 0}
            this.DataRetrivalRequestPeriodChange.emit(obj);
        }else if(requestFrequencyName == "1-Day"){
            this.RequestPeriodStatus = requestFrequencyName;
            let obj = {'statusName':this.RequestPeriodStatus , value : 1}
            this.DataRetrivalRequestPeriodChange.emit(obj);
        }else if(requestFrequencyName == "1-Weak"){
            this.RequestPeriodStatus = requestFrequencyName;
            let obj = {'statusName':this.RequestPeriodStatus , value : 7}
            this.DataRetrivalRequestPeriodChange.emit(obj);
        }else if(requestFrequencyName == "1-Month"){
            this.RequestPeriodStatus = requestFrequencyName;
            let obj = {'statusName':this.RequestPeriodStatus , value : 30}
            this.DataRetrivalRequestPeriodChange.emit(obj);
        }else{
            this.RequestPeriodStatus = 'Req.Period';
        }
    }
    DataretrvalDeselectMain(){

    }
    deleteEmailScheduler(row){
        this.gstr2aService.deleteScheduledEmailHistory(row.requestID,row.emailType).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.snackBar.open('Deleted the defined scheduler.', 'OK', {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['success-snackbar']
                    });
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }
    viewVendorGSTINs(fileID, i, element, reportType){
        let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.ViewVendorGSTINs;
        dialogTemplate.AdditionalInfo = element;
        dialogTemplate.ReportType = reportType;
        console.log(dialogTemplate.AdditionalInfo);
        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });
    }
    download_GtaExcelDownload(element){
        this.gtaService.downloadGtaFile(element.gtaUid, element.gtaFileDescription,element.gtaOrganizationId).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            let currentdate = new Date();
            anchor.download = element.gtaFileDescription + '.csv';
            anchor.href = url;
            anchor.click();
            //this.downloadingElement = undefined;
        },
            error => {
                // this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
                // const filename = this.dataSource.data.find(item => item.fileId === fileID).fileName;
                this.snackBar.open(
                    'Error Downloading ' + 'GSTR6A_' + element.transactionType + '_' + element.gstin + '_' + element.reportedPeriod + '.xlsx' + '. | Status: ' + error.status + '. | Error Message: ' + error.name, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
                //this.downloadingElement = undefined;
            });


       this.gtaService.downloadGtaFile(element.gtaUid, element.gtaFileDescription,element.gtaOrganizationId)
    }
    TaxNoticeOperationSingleFile(action,element,type){
        if(action == 'Delete'){
          this.taxNoticeService.TaxNoticeOperation_SingleFile_Delete(action,element.folderId,element.taxNoticeId,element.fileFolder,element.filenameWithNoticeId,type).subscribe(
            data => {
              if ('200'.localeCompare(data.status) === 0) {
                this.snackBar.open('File deleted successfully', 'OK', {
                    panelClass: ['success-snackbar']
                  });
                  this.TaxNoticeRefresh.emit(element.taxNoticeId);
              }
              else {
                this.snackBar.open(data.message, 'OK', {
                  panelClass: ['alert-snackbar']
                });
              }
            })
          
        }
         else if(action == 'Download'){
            this.taxNoticeService.TaxNoticeOperation_SingleFile(action,element.folderId,element.taxNoticeId,element.fileFolder,element.filenameWithNoticeId,"").subscribe(
                data => {
                     const blob = new Blob([data], { type: data.type });
                     const url = window.URL.createObjectURL(blob);
                     const anchor = document.createElement('a');
                     anchor.download = element.fileName;
                     anchor.href = url;
                     anchor.click();
                    this.TaxNoticeRefresh.emit(element.taxNoticeId);
                })
         }
        
        }

        download_ForceMatch_SuccessReport(element)
        {
            this.isDownloading = true;
            this.globalApiService.downloadForceHistoryFile(element.fileId,'success').subscribe((data: any) => {
                const blob = new Blob([data], { type: data.type });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                let dateTime = new Date();
                anchor.download = 'ForceMatchHistory' + "_" + element.fileId + "_" + this.time
                anchor.href = url;
                anchor.click();
                this.isDownloading = false
            },
            error => {
                this.snackBar.open(
                    'Error Downloading the file' , 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
        }

        download_ForceMatch_ErrorReport(element)
        {
            this.isDownloading = true;
            this.globalApiService.downloadForceHistoryFile(element.errorFileID,'error').subscribe((data: any) => {
                const blob = new Blob([data], { type: data.type });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                let dateTime = new Date();
                anchor.download = 'ForceMatchHistoryError' + "_" + element.errorFileID + "_" + this.time
                anchor.href = url;
                anchor.click();
                this.isDownloading = false
            },
            error => {
                this.snackBar.open(
                    'Error Downloading the file' , 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
        }

}
export const Status_LIST = [
    {Name:'Select',value:'0'}, {Name:'Error',value:'4'},{Name:'In Progress',value:'2'},{Name:'In Queue',value:'1'},
    {Name:'No Data',value:'34'},{Name:'Success',value:'3'}
];
export const RequestPeriod_LIST = [
    {Name:'Select',value:'0'}, {Name:'1-Day',value:'1'},{Name:'1-Weak',value:'7'},{Name:'1-Month',value:'30'}
];
